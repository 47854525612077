<template>
  <ContainerBasic
    container-type="PAGE"
    :class="blockData.size !== 'h1' ? 'page__headline' : 'page__headline--h1'"
  >
    <HeadlineDynamic
      :has-decoration="true"
      :headline="blockData.headline"
      :stroke-width="1"
      :level="blockData.size"
      :testid="generateTestIdFromHeadline(blockData.headline)"
    />
  </ContainerBasic>
</template>

<script setup lang="ts">
import ContainerBasic from '@/components/components/container-basic.vue';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';
import type { HeadlineData } from '../blockType';

defineProps({
  blockData: {
    type: Object as PropType<HeadlineData>,
    required: true,
    default: {} as HeadlineData,
  },
});
function generateTestIdFromHeadline(headline: string) {
  const headlineParts = headline.split(' ').map((part) => {
    const part1 = part[0].toUpperCase() + part.substring(1, part.length);
    return part1;
  });
  headline = headlineParts.join('');

  return `headline${headline}`;
}
</script>
<style scoped lang="postcss"></style>
